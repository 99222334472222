import jwt from 'jsonwebtoken';
import { v4 as uuidv4 } from 'uuid';
import mock from 'src/utils/mock';
import wait from 'src/utils/wait';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { WebAssetOutlined } from '@material-ui/icons';
import { constant } from 'lodash';
import { useSnackbar } from 'notistack';
const JWT_SECRET = 'devias-top-secret-key';
const JWT_EXPIRES_IN = '2 days';


let users = [
  {

    id: '5e86809283e28b96d2d38537',
    avatar: '/static',
    canHire: false,
    country: 'USA',
    email: 'demo@devias.io',
    isPublic: true,
    name: 'Katarina Smith',
    password: 'Password123',
    phone: '+40 777666555',
    role: 'admin',
    state: 'New York',
    tier: 'Premium'

  }
];

mock.onPost('/api/account/login').reply(async (config) => {
  try {
    // await wait(1000);
    const { email, password } = JSON.parse(config.data);
    const data = {
      "username": email,
      "password": password
    }
    const request = await axios.post('user/authenticate/admin', data)
    let authToken: any;
    if (request.status === 200) {
      authToken = request.data.jwtToken;
      localStorage.setItem("authToken", request.data.jwtToken);
    }
    const res = await axios.get('user/' + request.data.userId, {
      headers: {
        'Authorization': `Bearer ${request.data.jwtToken}`
      }
    })
    let _request;
    if (res.status === 200) {
      _request = await axios.get('businesses/BusinessRecord/' + res.data.businessInfoId, {
        headers: {
          'Authorization': `Bearer ${request.data.jwtToken}`
        }
      });
    }

    let user = users.find((_user) => _user.email === email);
    if (!request.data) {
      return [400, { message: 'Please check your email and password' }];
    }

    const accessToken = jwt.sign(
      { userId: request.data.userId },
      JWT_SECRET,
      { expiresIn: JWT_EXPIRES_IN }
    );

    let businessUser = _request && _request.data ? {
      id: request.data.userId,
      email: request.data.userEmail,
      name: _request.data.businessName,
      address: _request.data.address,
      state: _request.data.state,
      website: _request.data.website,
      city: _request.data.city,
      postalcode: _request.data.postalCode,
      countryName: _request.data.country,
      description: _request.data.description,
      businessInfoId: _request.data.businessInfoId,
      ownerId: _request.data.ownerId,
      psd: password,
      userRole: res.data.userRole,
      logo: _request.data.logo
    } : { ...res.data }

    return [200, {
      accessToken,
      authToken,
      user: businessUser
    }];
  } catch (err) {
    console.error(err);
    return [500, { message: err?.response?.data || 'User not Found.' }];
  }
});

mock.onPost('/api/account/register').reply(async (config) => {
  function uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }
  try {
    var guid = uuidv4();
    await wait(1000);
    const { email, name, password } = JSON.parse(config.data);
    const request = await axios({
      method: "post",
      url: `user/Register/` + email + `/` + password,
      data:
      {
        "businessUserId": guid,
        "email": email,
        "password": password,
        "active": "true"
      }
    })
    let user = users.find((_user) => _user.email === email);

    if (request.data) {
      return [200, { message: 'User account created.' }];
    }

    user = {
      id: guid,
      avatar: user.avatar,
      canHire: false,
      country: null,
      email: request.data.email,
      isPublic: true,
      name,
      password,
      phone: null,
      role: 'admin',
      state: request.data.active,
      tier: 'Standard'
    };

    const accessToken = jwt.sign(
      { userId: request.data.businessUserId },
      JWT_SECRET,
      { expiresIn: JWT_EXPIRES_IN }
    );

    return [200, {
      accessToken,
      user: {
        id: user.id,
        avatar: user.avatar,
        email: user.email,
        name: user.name,
        tier: user.tier
      }
    }];
  } catch (err) {
    console.error(err);
    return [500, { message: 'Internal server error' }];
  }
});

mock.onGet('/api/account/me').reply((config) => {
  try {
    const { Authorization } = config.headers;

    if (!Authorization) {
      return [401, { message: 'Authorization token missing' }];
    }

    const accessToken = Authorization.split(' ')[1];
    const { userId } = jwt.verify(accessToken, JWT_SECRET) as any;
    const user = users.find((_user) => _user.id === userId);

    if (!user) {
      return [401, { message: 'Invalid authorization token' }];
    }

    return [200, {
      user: {
        id: user.id,
        avatar: user.avatar,
        email: user.email,
        name: user.name,
        tier: user.tier
      }
    }];
  } catch (err) {
    console.error(err);
    return [500, { message: 'Internal server error' }];
  }
});

mock.onGet('/api/account/settings').reply(200, {
  settings: {}
});

mock.onGet('/api/account/subscription').reply(200, {
  subscription: {
    name: 'Premium',
    price: 29,
    currency: '$',
    proposalsLeft: 12,
    templatesLeft: 5,
    invitesLeft: 24,
    adsLeft: 10,
    hasAnalytics: true,
    hasEmailAlerts: true
  }
});

