import React from "react";
import { version } from "../version";

const Version = () => {
  return (
    <>
      Admin v{ version }
    </>
  );
}

export default Version;