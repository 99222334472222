/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import type { FC, ReactNode } from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import ThumbsUpDownOutlinedIcon from '@material-ui/icons/ThumbsUpDownOutlined';
import {
  Avatar,
  Box,
  Chip,
  Divider,
  Drawer,
  Hidden,
  Link,
  List,
  ListSubheader,
  Typography,
  makeStyles
} from '@material-ui/core';
import ReceiptIcon from '@material-ui/icons/ReceiptOutlined';
import LanguageIcon from "@material-ui/icons/LanguageOutlined";
import {
  Briefcase as BriefcaseIcon,
  Calendar as CalendarIcon,
  ShoppingCart as ShoppingCartIcon,
  Folder as FolderIcon,
  BarChart as BarChartIcon,
  Lock as LockIcon,
  UserPlus as UserPlusIcon,
  AlertCircle as AlertCircleIcon,
  Trello as TrelloIcon,
  User as UserIcon,
  Layout as LayoutIcon,
  Edit as EditIcon,
  DollarSign as DollarSignIcon,
  Mail as MailIcon,
  MessageCircle as MessageCircleIcon,
  PieChart as PieChartIcon,
  Share2 as ShareIcon,
  Users as UsersIcon,
  Database
} from 'react-feather';
import Logo from 'src/components/Logo';
import useAuth from 'src/hooks/useAuth';
import NavItem from './NavItem';
import strings from 'src/constants/Localization';

interface NavBarProps {
  onMobileClose: () => void;
  openMobile: boolean;
}

interface Item {
  href?: string;
  icon?: ReactNode;
  info?: ReactNode;
  items?: Item[];
  title: string;
  code?: string;
}

interface Section {
  items: Item[];
  subheader: string;
}

const sections: Section[] = [
  // {
  //   subheader: 'Reports',
  //   items: [
  //     {
  //       title: 'Dashboard',
  //       icon: PieChartIcon,
  //       href: '/app/reports/dashboard'
  //     },
  //     {
  //       title: 'Dashboard Alternative',
  //       icon: BarChartIcon,
  //       href: '/app/reports/dashboard-alternative'
  //     }
  //   ]
  // },
  {
    subheader: 'Management',
    items: [
      // {
      //   title: 'Customers',
      //   icon: UsersIcon,
      //   href: '/app/management/customers',
      //   items: [
      //     {
      //       title: 'List Customers',
      //       href: '/app/management/customers'
      //     },
      //     {
      //       title: 'View Customer',
      //       href: '/app/management/customers/1'
      //     },
      //     {
      //       title: 'Edit Customer',
      //       href: '/app/management/customers/1/edit'
      //     }
      //   ]
      // },
      // {
      //   title: 'Products',
      //   icon: ShoppingCartIcon,
      //   href: '/app/management/products',
      //   items: [
      //     {
      //       title: 'List Products',
      //       href: '/app/management/products'
      //     },
      //     {
      //       title: 'Create Product',
      //       href: '/app/management/products/create'
      //     }
      //   ]
      // },
      {
        title: 'Business',
        icon: BriefcaseIcon,
        href: '/app/management/business',
      },
      // {
      //   title: 'Activity',
      //   icon: ThumbsUpDownOutlinedIcon,
      //   href: '/app/management/activity',
      //   items: [
      //     {
      //       title: 'ListActivity',
      //       href: '/app/management/activity'
      //     },
      //     {
      //       title: 'UserActivity',
      //       href: '/app/management/user/activity'
      //     }
      //   ]
      // },
      {
        title: 'Locations',
        icon: ReceiptIcon,
        href: '/app/management/location',
        items: [
          {
            title: 'ListLocations',
            href: '/app/management/location'
          },
          // {
          //   title: 'ConfirmActivity ',
          //   href: '/app/management/confirm-activitylist'
          // }
        ]
      },
      // {
      //   title: 'Users',
      //   icon: UsersIcon,
      //   items: [
      //     {
      //       title: 'UserList',
      //       href: '/app/management/users',
      //     },
      //     {
      //       title: 'UserRole',
      //       href: '/app/management/user-role',
      //     }
      //   ]
      // }
    ]
  },
  // {
  //   subheader: 'Settings',
  //   items: [
  //     {
  //       title: 'Account',
  //       href: '/app/account',
  //       icon: UserIcon
  //     },
  //   ],

  // },{
  //   subheader: '',
  //   items:[
  //     {
  //       title: 'Languages',
  //       icon: LanguageIcon,
  //       items: [
  //         {
  //           title: 'English',
  //           href: '#',
  //           code: 'en'
  //         },
  //         {
  //           title: 'French',
  //           href: '#',
  //           code: 'fr'
  //         }
  //       ]
  //     }
  //   ]
  // }
];

function renderNavItems({
  items,
  pathname,
  depth = 0
}: {
  items: Item[];
  pathname: string;
  depth?: number;
}) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({
  acc,
  pathname,
  item,
  depth
}: {
  acc: any[];
  pathname: string;
  item: Item;
  depth: number;
}) {
  const key = item.title + depth;
  const open = matchPath(pathname, {
    path: item.href,
    exact: false
  });
  const isAdmin = JSON.parse(localStorage.getItem("isAdmin"));
  const isKidAdvisorAdmin = JSON.parse(localStorage.getItem("isKidAdvisorAdmin"));
  const isKidAdvisorClerk = JSON.parse(localStorage.getItem("isKidAdvisorClerk"));

  const showBusiness = isKidAdvisorAdmin ? isKidAdvisorAdmin : isKidAdvisorClerk ? isKidAdvisorClerk : false
  const showMetaData = isKidAdvisorAdmin ? isKidAdvisorAdmin : false
  const hideLocation = !isKidAdvisorAdmin ? !isKidAdvisorAdmin : !isKidAdvisorClerk ? !isKidAdvisorClerk: true
  const hideListActivity = !isKidAdvisorAdmin

  if ((item.title === "Users" && !isAdmin) || (item.title === "Account" && !isAdmin)) {

  }
  else if ((item.title === "Business" && !showBusiness)) {

  }
  else if ((item.title === "MetaData" && !showMetaData)) {

  }
  else if ((item.title === "Locations" && hideLocation)) {

  }
  else if ((item.title === "ListActivity" && hideListActivity)) {

  }
  else {
    if (item.items) {
      acc.push(
        <NavItem
          depth={depth}
          icon={item.icon}
          info={item.info}
          key={key}
          open={Boolean(open)}
          title={item.title}
        >
          {renderNavItems({
            depth: depth + 1,
            pathname,
            items: item.items
          })}
        </NavItem>
      );
    } else {
      acc.push(
        <NavItem
          depth={depth}
          href={item.href}
          icon={item.icon}
          info={item.info}
          key={key}
          title={item.title}
          code={item.code}
        />
      );
    }
  }
  return acc;
}

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  }
}));

const NavBar: FC<NavBarProps> = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const { user, isAdmin, isKidAdvisorClerk, isKidAdvisorAdmin } = useAuth();

  useEffect(() => {
    strings.setLanguage(localStorage.getItem("Language") ? localStorage.getItem("Language") : "en")
    return () => {

    }
  }, [])

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
    >
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box
            p={2}
            display="flex"
            justifyContent="center"
          >
            {/* <RouterLink to="/">
              <Logo src="/static/logo.svg" />
            </RouterLink> */}
          </Box>
        </Hidden>
        <Box p={2}>
          <Box
            display="flex"
            justifyContent="center"
          >
            {/* <RouterLink to="/app/account">
              <Avatar
                alt="User"
                className={classes.avatar}
                src={user.logo}
              />
            </RouterLink> */}
          </Box>
          <Box
            mt={2}
            textAlign="center"
          >
            <Link
              component={RouterLink}
              to="/app/account"
              variant="h5"
              color="textPrimary"
              underline="none"
            >
              {/* {user.name ? user.name : user.email} */}
              <br />
              <span style={{ fontSize: "13px" }}>{isKidAdvisorAdmin && "Kid Advisor Admin"}</span>
              <span style={{ fontSize: "13px" }}>{isKidAdvisorClerk && "Kid Advisor Clerk"}</span>
            </Link>
            <Typography
              variant="body2"
              color="textSecondary"
            >
              {/* Your tier: */}
              {' '}
              <Link
                component={RouterLink}
                to="/pricing"
              >
                {user.tier}
              </Link>
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box p={2}>
          {sections.map((section) => {
            if ((section.subheader === "Settings" && !isAdmin) || (section.subheader === "Auth" && !isAdmin)) {

            } else {
              return (
                <List
                  key={section.subheader}
                  subheader={(
                    <ListSubheader
                      disableGutters
                      disableSticky
                    >
                      {strings[section.subheader]}
                    </ListSubheader>
                  )}
                >
                  {renderNavItems({
                    items: section.items,
                    pathname: location.pathname
                  })}
                </List>
              )
            }
          }
          )}
        </Box>
        {/* <Divider /> */}
        {/* <Box p={2}> */}
        {/* <Box
            p={2}
            borderRadius="borderRadius"
            bgcolor="background.dark"
          > */}
        {/* <Typography
              variant="h6"
              color="textPrimary"
            >
              Need Help?
            </Typography>
            <Link
              variant="subtitle1"
              color="secondary"
              component={RouterLink}
              to="/docs"
            >
              Check our docs
            </Link> */}
        {/* </Box> */}
        {/* </Box> */}
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
