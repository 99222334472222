import React, {
  createContext,
  useEffect,
  useReducer
} from 'react';
import type { FC, ReactNode } from 'react';
import jwtDecode from 'jwt-decode';
import type { User } from 'src/types/user';
import SplashScreen from 'src/components/SplashScreen';
import axios from 'src/utils/axios';
import { Redirect, useHistory } from 'react-router';
import { useSnackbar } from 'notistack';

interface AuthState {
  isInitialised: boolean;
  isAuthenticated: boolean;
  user: User | null;
  isAdmin: boolean;
  isKidAdvisorAdmin: boolean;
  isKidAdvisorClerk: boolean;
  authToken: string;
}

interface AuthContextValue extends AuthState {
  method: 'JWT',
  login: (email: string, password: string) => Promise<void>;
  logout: () => void;
  register: (email: string, name: string, password: string) => Promise<void>;
  signInWithGoogle: any;
  updateUser: any;
}

interface AuthProviderProps {
  children: ReactNode;
}

type InitialiseAction = {
  type: 'INITIALISE';
  payload: {
    isAuthenticated: boolean;
    user: User | null;
    authToken: string | null;
  };
};

type LoginAction = {
  type: 'LOGIN';
  payload: {
    user: User;
    authToken: string;
  };
};

type LogoutAction = {
  type: 'LOGOUT';
};

type RegisterAction = {
  type: 'REGISTER';
  payload: {
    user: User;
  };
};

type UpdateAction = {
  type: 'UPDATE';
  payload: {
    user: User | null;
  };
};

type Action =
  | InitialiseAction
  | LoginAction
  | LogoutAction
  | RegisterAction 
  | UpdateAction;

const initialAuthState: AuthState = {
  isAuthenticated: false,
  isInitialised: false,
  user: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : null,
  isAdmin: localStorage.getItem("isAdmin") ? JSON.parse(localStorage.getItem("isAdmin")) : false,
  isKidAdvisorAdmin: localStorage.getItem("isKidAdvisorAdmin") ? JSON.parse(localStorage.getItem("isKidAdvisorAdmin")) : false,
  isKidAdvisorClerk: localStorage.getItem("isKidAdvisorClerk") ? JSON.parse(localStorage.getItem("isKidAdvisorClerk")) : false,
  authToken: localStorage.getItem("authToken") ? localStorage.getItem("authToken") : null
};

const isValidToken = (accessToken: string): boolean => {
  if (!accessToken) {
    return false;
  }

  const decoded: any = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

const setSession = (accessToken: string | null): void => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem('accessToken');
    delete axios.defaults.headers.common.Authorization;
  }
};

const reducer = (state: AuthState, action: Action): AuthState => {
  switch (action.type) {
    case 'INITIALISE': {
      const { isAuthenticated, user } = action.payload;
      const isKidAdvisorAdmin = user ? user.userRole.userRoleName?.toLowerCase() === "kidadvisoradmin" ? true : false : false;
      const isKidAdvisorClerk = user ? user.userRole.userRoleName?.toLowerCase() === "kidadvisorclerk" ? true : false : false;
      const isAdmin = isKidAdvisorAdmin;
      localStorage.setItem("isKidAdvisorAdmin", JSON.stringify(isKidAdvisorAdmin));
      localStorage.setItem("isAdmin", JSON.stringify(isAdmin));
      localStorage.setItem("isKidAdvisorClerk", JSON.stringify(isKidAdvisorClerk))
      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        user
      };
    }
    case 'LOGIN': {
      const { user, authToken } = action.payload;
      localStorage.setItem("user", JSON.stringify(user));
      const isKidAdvisorAdmin = user.userRole.userRoleName?.toLowerCase() === "kidadvisoradmin" ? true : false;
      const isKidAdvisorClerk = user.userRole.userRoleName?.toLowerCase() === "kidadvisorclerk" ? true : false;
      const isAdmin = isKidAdvisorAdmin;
      localStorage.setItem("isKidAdvisorAdmin", JSON.stringify(isKidAdvisorAdmin));
      localStorage.setItem("isAdmin", JSON.stringify(isAdmin));
      localStorage.setItem("isKidAdvisorClerk", JSON.stringify(isKidAdvisorClerk))
      return {
        ...state,
        isAuthenticated: true,
        user,
        isAdmin,
        isKidAdvisorAdmin,
        isKidAdvisorClerk,
        authToken
      };
    }
    case 'LOGOUT': {
      localStorage.removeItem("user");
      localStorage.removeItem("isAdmin");
      localStorage.removeItem("isKidAdvisorAdmin");
      localStorage.removeItem("isKidAdvisorClerk");
      localStorage.removeItem("authToken");
      return {
        ...state,
        isAuthenticated: false,
        user: null
      };
    }
    case 'REGISTER': {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user
      };
    }
    case 'UPDATE': {
      const { user } = action.payload;

      return {
        ...state,
        user
      }
    }
    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext<AuthContextValue>({
  ...initialAuthState,
  method: 'JWT',
  login: () => Promise.resolve(),
  logout: () => { },
  register: () => Promise.resolve(),
  signInWithGoogle: () => Promise.resolve(),
  updateUser: () => { },
});

export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);
  const History = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const login = async (email: string, password: string) => {
    const response = await axios.post<{ accessToken: string; user:User, authToken: string }>('/api/account/login', { email, password });
    const { accessToken, user, authToken } = response.data;
    setSession(accessToken);
    dispatch({
      type: 'LOGIN',
      payload: {
        user,
        authToken
      }
    });
  };

  const logout = () => {
    setSession(null);
    dispatch({ type: 'LOGOUT' });
  };

  const register = async (email: string, name: string, password: string) => {
    const response = await axios.post<{ accessToken: string; user: User }>('/api/account/register', {
      email,
      name,
      password
    });

    if (response.status === 200) {
      enqueueSnackbar('Your Registration is completed.', {
        variant: 'success'
      });
      History.push("/login");
    }

  };

  const signInWithGoogle = () => {
    console.log('Signing in with google')
  }

  const updateUser = (payload: any) => {
    dispatch({
      type: 'UPDATE',
      payload: {
        user: {...state.user, ...payload},
      }
    });

  };

  useEffect(() => {
    const initialise = async () => {
      try {
        const accessToken = window.localStorage.getItem('accessToken');

        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);

          const response = await axios.get<{ user: User; }>('/api/account/me');
          const { user } = response.data;

          dispatch({
            type: 'INITIALISE',
            payload: {
              isAuthenticated: true,
              user,
              authToken: null
            }
          });
        } else {
          dispatch({
            type: 'INITIALISE',
            payload: {
              isAuthenticated: false,
              user: null,
              authToken: null
            }
          });
        }
      } catch (err) {
        console.error("->", err);
        const user = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : null
        const authToken = localStorage.getItem("authToken") ? localStorage.getItem("authToken") : null
        dispatch({
          type: 'INITIALISE',
          payload: {
            isAuthenticated: user ? true : false,
            user: user,
            authToken: authToken,
          }
        });
      }
    };

    initialise();
  }, []);

  if (!state.isInitialised) {
    return <SplashScreen />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'JWT',
        login,
        logout,
        register,
        signInWithGoogle,
        updateUser
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;