export const APP_VERSION = '3.1.0';

export const ENABLE_REDUX_DEV_TOOLS = true;

export const THEMES = {
  LIGHT: 'LIGHT',
  ONE_DARK: 'ONE_DARK',
  UNICORN: 'UNICORN'
}

export const EMAIL_VALIDATION = /^\S+@\S+$/;

export const USER_ROLES = {
  BUSINESS_ADMIN: "Admin",
  BUSINESS_CLERK: "Clerk",
  KIDADVISOR_ADMIN: "KidAdvisorAdmin",
  KIDADVISOR_CLERK: "KidAdvisorClerk"
}
